import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "./shared/shared.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { ButtonsComponent } from "./components/buttons/buttons.component";
import { LoginComponent } from "./auth/login/login.component";
// for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// // for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// // for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { environment } from "src/environments/environment";
import { AuthGuard } from "./shared/guard/auth.guard";
import { LightboxModule } from "@ngx-gallery/lightbox";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import {Router} from '@angular/router';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';

import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [
    AppComponent, 
    ButtonsComponent, 
    LoginComponent
    
  
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    SharedModule,
    OverlayModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    BrowserAnimationsModule,
    LightboxModule,
    ToastrModule.forRoot(),
    provideFirebaseApp(() =>
    initializeApp({
      apiKey: "AIzaSyDm5YMJDSUWtghehwfx0EktgjUwO4ZVZ9Y",
      authDomain: "eyas-2f83b.firebaseapp.com",
      projectId: "eyas-2f83b",
      storageBucket: "eyas-2f83b.appspot.com",
      messagingSenderId: "566394555409",
      appId: "1:566394555409:web:e83d8ca163cd468344b5b0",
      measurementId: "G-M48YTGGCWC"
    })
  ),

  
  provideMessaging(() => getMessaging()),
   
  ],
  providers: [
      AuthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
