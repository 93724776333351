import {Component, HostBinding, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AuthService} from './features/auth/auth.service';
import {MessagingService} from './features/auth/messaging.service';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    message$: Observable<any>;
    @HostBinding('@.disabled')
    public animationsDisabled = false;
    message: any

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }


    toggleAnimations() {
        this.animationsDisabled = !this.animationsDisabled;
    }

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private loader: LoadingBarService, public authService: AuthService,
    ) {

      // throw new Error('testing sentry');
    }

    ngOnInit(): void {
        this.loadCurrentUser();

    }

    loadCurrentUser() {
        // const token = localStorage.getItem('token');
        // this.authService.loadCurrentUser(token);
    }

}
