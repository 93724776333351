<section class="py-5" *ngIf="articles.length">
    <div class="container">
        <h2 class="text-center mb-5">اقرأ المزيد من المقالات</h2>
        <div class="row">
            <div class="col-md-4 mb-4" *ngFor="let article of articles">
                <div class="card shadow-none border-0">
                    <a [routerLink]="['/blog', article.category.name, article.slug]"  >
                        <img src="{{article.img}}" class="card-img-top rounded rounded-4" alt="Article Image">
                    </a>
                    <span class="badge blog-category-name" style="background: rgb(205, 165, 81)">{{ article.category.name }}</span>
                    <div class="card-body px-0">
                        <h3 class="card-title">{{ article.title_ar }}</h3>
                        <p class="card-text">{{ removeHtmlTags(article.description_ar) }}</p>
                        <a [routerLink]="['/blog', article.category.name, article.slug]" class="btn btn-primary">اقرأ المزيد</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
