    <app-navbar></app-navbar>

<!-- Main Content -->
<div class="mt-5">
    <div class="row post-background m-0 p-0" style="background-image: url('{{post.img}}');">
        <div class="post-content">
            <div class="badge bg-secondary single-category">{{post.category.name}}</div>
            <h1 class="mt-3">
                {{post.title_ar}}
                <p>{{removeHtmlTags(post.summary)}}</p>
            </h1>

        </div>
    </div>
    <div class="container post-description">
        <div class="row my-5">
            <div class="col-12">
                <div [innerHTML]="post.description_ar"></div>
            </div>
        </div>

        <!-- More Articles Section -->
        <app-more-articles></app-more-articles>

    </div>
</div>



<section class="main-footer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="eyas-logo">
                    <img src="../../../../../assets/images/growth/Eyas_logo2.svg" alt="">
                </div>
            </div>
        </div>
        <div class="row mt-4 footer-content">
            <div class="col-lg-3 mt-4">
                <h4 class="link-header">{{ "CONTACTUS" |translate }}</h4>
                <div>
                    <ul class="footer-links">
                        <li><a href="mailto:cs@eyasapp.com">cs@eyasapp.com</a></li>
                        <li> 966-50-1100712 <span>+</span></li>
                        <li>{{ "SUDIA" |translate }}</li>
                    </ul>
                </div>
                <div class="user-card">
                    <ul class="card-social d-flex">

                        <li><a href="https://twitter.com/eyasapp"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/eyasapp/"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/eyasapp/"><i class="fa fa-linkedin"></i></a></li>
                        <li><a href="https://www.facebook.com/EyasApp"><i class="fa fa-facebook"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-12 mt-8 col-footer">
                <h4 class="link-header">{{ "OUR_SERVICES" |translate }}</h4>
                <div>
                    <ul class="footer-links  p-0">
                        <li><a href="https://landing.eyasapp.com/business">{{ "SERVICE_1" |translate }}</a></li>
                        <li><a href="https://landing.eyasapp.com/court-case">{{ "SERVICE_2" |translate }}</a></li>
                        <li><a href="https://landing.eyasapp.com/">{{ "SERVICE_3" |translate }}</a></li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-6 mt-4 col-footer">
                <h4 class="link-header">{{"IMPORTANT_LINKS" |translate}}</h4>
                <div>
                    <ul class="footer-links  p-0">
                      <li><a href="#">{{"LINK" |translate}}</a></li>
                      <li><a href="#">{{"LINK" |translate}}</a></li>
                      <li><a href="#">{{"LINK" |translate}}</a></li>
                    </ul>
                   </div>
            </div> -->


            <div class="col-lg-3 mt-4 ">
                <!-- <h4 class="link-header download-service">{{"DOWNLOAD_APP" |translate}}</h4> -->
                <div class="ul-list">
                    <ul class="footer-links p-0">
                        <li>
                            <h4 class="link-header download-service">{{ "DOWNLOAD_APP" |translate }}</h4>
                        </li>
                        <li><a href="https://apps.apple.com/kh/developer/eyas-technology/id1537604634"><img
                                src="../../../../../assets/images/growth/45px Blue.png" alt="" class="google"></a>
                        </li>
                        <li><a href="https://play.google.com/store/apps/details?id=com.eyas.user&pcampaignid=web_share"><img
                                src="../../../../../assets/images/growth/google.png" alt="" class="google"></a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sub-footer">
    <div class="container">
        <div class="row">
            <div class="col-12 rights-content">
                <span class="rights"
                      (click)="navigateTo('https://landing.eyasapp.com/terms-conditions', '_blank')">{{
                        "ALL_RIGHTS1"
                            |translate
                    }}</span>
                <span class="rights">{{ "ALL_RIGHTS2" |translate }}</span>
            </div>
        </div>
    </div>
</section>