import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "src/app/features/auth/auth.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, public authService: AuthService) {}

  canActivate(): Observable<any> {
    this.authService.loadCurrentUser(localStorage.getItem("token"));
    return this.authService.currentUser$.pipe(
      map((user) => {
        if (!user || user === null) {
          this.router.navigate(["/auth/signup"]);
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}
