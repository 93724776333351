import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppDataService } from "../../services/app-data.service";
import { Router } from "@angular/router";
import { take } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  currentRoute: string;

  constructor(
    public translate: TranslateService,
    private appdataService: AppDataService,
    private router: Router
  ) {}
  currentSection = "home";
  isCondensed = false;
  // Set Topbar Option
  Menuoption = "center";
  Settingicon = false;
  isLoggined = false;
  ngOnInit(): void {
    if (localStorage.getItem("token")) {
      this.appdataService.setIsLoggined(true);
    }
    this.appdataService.isLoggined$.subscribe((value) => {
      this.isLoggined = value;
    });

    this.router.events.pipe(take(1)).subscribe((event: any) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "pageView",
        properties: {
          pageTitle: event.url || event.routerEvent.url,
          userType: this.isLoggined ? localStorage.getItem("type") : "VISITOR",
          userName: this.isLoggined ? localStorage.getItem("name") : "",
        },
      });
    });
  }

  windowScroll() {
    if (
      document.body.scrollTop > 520 ||
      document.documentElement.scrollTop > 520
    ) {
      if (this.isCondensed === true) {
        console.log("ttttttttttt");

        const topnavElement = document.getElementById("topnav");
        if (topnavElement) {
          topnavElement.classList.remove("nav-sticky");
        }
      } else {
        const topnavElement = document.getElementById("topnav");
        if (topnavElement) {
          topnavElement.classList.add("nav-sticky");
        }
      }
    } else {
      const topnavElement = document.getElementById("topnav");
      if (topnavElement) {
        topnavElement.classList.remove("nav-sticky");
      }
    }
  }

  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    console.log(this.isCondensed);
    if (this.isCondensed === true) {
      console.log("ttttttttttt");
      const topnavElement = document.getElementById("topnav");
      if (topnavElement) {
        topnavElement.classList.remove("nav-sticky");
      }
    }
    const navigationElement = document.getElementById("navigation");
    if (navigationElement) {
      if (this.isCondensed) {
        navigationElement.style.display = "block";
      } else {
        navigationElement.style.display = "none";
      }
    }
    if (this.isCondensed) {
      const topnav = document.getElementById("topnav");
      if (topnav) {
        // topnav.style.background = '#fff';
        topnav.style.setProperty("background", "#fff", "important");
      }
    } else {
      if (
        document.body.scrollTop > 520 ||
        document.documentElement.scrollTop > 520
      ) {
        const topnav = document.getElementById("topnav");
        if (topnav) {
          topnav.style.background = "transparent";
          topnav.classList.add("nav-sticky");
        }
      } else {
        const topnav = document.getElementById("topnav");
        if (topnav) {
          topnav.style.background = "transparent";
        }
      }
    }
  }
  isAboutRouteActive(): boolean {
    return this.router.url.includes("About-Eyas");
  }
  switchLanguage() {
    const currentLang = this.translate.currentLang;
    this.translate.use(currentLang === "ar" ? "en" : "ar");
    // window.location.reload();
  }

  scrollToBottom() {
    if(this.isCondensed)
      this.toggleMenu();
    window.scrollTo(0, document.body.scrollHeight);
  }

  logout() {
    localStorage.clear();
    this.appdataService.setIsLoggined(false);
    Swal.fire({
      title: "تسجيل الخروج",
      text: "تم تسجيل الخروج بنجاح",
      showConfirmButton: false,
      timer: 1500,
    });
  }

  navigateTo(link: string, target: string) {
    if (link.includes("http")) window.open(link, target);
    else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "button_request_start",
        properties: {
          requestLocation: "menu",
          categoryOfInterest: "",
          pageTitle: "Home",
        },
      });
      this.router.navigate([link]);
    }
  }
}
