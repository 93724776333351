import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-more-articles',
  templateUrl: './more-articles.component.html',
  styleUrls: ['./more-articles.component.scss']
})
export class MoreArticlesComponent {
  @Input() articles: any[];

  removeHtmlTags(text: string): string {
    const textFormatted =  text.replace(/<[^>]*>/g, '');
    return textFormatted.length > 120 ? textFormatted.substring(0, 120) + '...' : textFormatted;
  }
}
