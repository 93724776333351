// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  apiUrl: "https://business-api.algorithms.ws/api/v1",
  growthApiUrl: "https://eyas-api.algorithms.ws/api/v1",
  appUrl: "https://eyasapp.com",
  version: {
    number: 1,
    realaseDate: new Date("2023-07-09"),
  },
  mixpanel: {
    token: "0df65993ea7cd6e5009eb1b021074a2d",
  },
  moyasar: {
    publishableKey: "pk_test_VVfatLqvnFXnNgWCGHh4qCPFivq9rQ5U9aetJm1p",
    secretKey: "sk_test_tA3jwL6i14wTwyCAkndd23MRoKhpA9fVhZYkfdyt",
    applePayMerchant: "https://api.moyasar.com/v1/applepay/initiate"
  },

  firebaseConfig: {
    apiKey: "AIzaSyDm5YMJDSUWtghehwfx0EktgjUwO4ZVZ9Y",
    authDomain: "eyas-2f83b.firebaseapp.com",
    projectId: "eyas-2f83b",
    storageBucket: "eyas-2f83b.appspot.com",
    messagingSenderId: "566394555409",
    appId: "1:566394555409:web:e83d8ca163cd468344b5b0",
    //  measurementId: "G-M48YTGGCWC"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
