export const productData = [
   {
      "id": 1,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "White Tshirt",
      "note": "Full width white t-shirt",
      "discription": "Rock Paper Scissors Womens Tank Top High Neck Cotton Top Stylish Women Top.",
      "discountPrice": "320.00",
      "status": "none",
      "price": 100.00,
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "White",
          "gray"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 2,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Wonder Woman",
      "note": "Full width and sleeveless top",
      "discription": "Rock Paper Scissors Womens Tank Top High Neck Cotton Top Stylish Women Top.",
      "discountPrice": "320.00",
      "status": "sale",
      "price": 260.00,
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "green",
          "gray"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Levis",
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 3,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Man's Jacket",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "450.00",
      "price": "360.00",
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "White",
          "gray",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Spykar",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 4,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Roadster",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "250.00",
      "price": "526.00",
      "status": "50%",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "red",
          "gray",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Levis",
          "Lee",
          "Hudson"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 5,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "250.00",
      "price": 206.00,
      "stock": "In stock",
      "status": "none",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "green",
          "gray",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Denien",
          "Diesel",
          "Spykar"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 6,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Wonder Woman",
      "note": "Simply dummy text of the printing",
      "discription": "Rock Paper Scissors Womens Tank Top High Neck Cotton Top Stylish Women Top.",
      "discountPrice": "450.00",
      "price": 926.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "purple",
          "orange",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Lee",
          "Lifestyle",
          "Nike"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 7,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Man's Jacket",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "350.00",
      "price": 1000.00,
      "status": "sale",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "orange",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 8,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Roadster",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "250.00",
      "price": 500.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "purple",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 9,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "320.00",
      "price": 826.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "White",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Spykar"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 10,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Roadster",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "250.00",
      "price": 500.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "purple",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 11,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Woman T-shirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "450.00",
      "price": 426.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "White",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 12,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Man's Jacket",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "450.00",
      "price": 626.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "White",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 13,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "350.00",
      "price": 450.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "White",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 14,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "350.00",
      "price": 26.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "White",
          "blue",
          "yellow"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 15,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Woman T-shirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "450.00",
      "price": 26.00,
      "status": "Hot",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "black",
          "blue",
          "yellow"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 16,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Man's Jacket",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "410.00",
      "price": 210.00,
      "status": "none",
      "stock": "Out of stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "black",
          "pink",
          "yellow"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Hudson"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 17,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "780.00",
      "price": 850.00,
      "status": "none",
      "stock": "Out of stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "black",
          "pink",
          "yellow"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 18,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "420.00",
      "price": 300.00,
      "status": "none",
      "stock": "Out of stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "black",
          "pink",
          "yellow"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel",
          "Hudson",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 19,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Woman T-shirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "250.00",
      "price": 120.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "red",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Denizen",
          "Levi's"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 20,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Man's Jacket",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "140.00",
      "price": 120.00,
      "status": "none",
      "stock": "Out of stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "gray",
          "pink",
          "yellow"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 21,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "150.00",
      "price": 250.00,
      "status": "none",
      "stock": "Out of stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "black",
          "pink",
          "blue"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 22,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Women Skirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "30.00",
      "price": 140.00,
      "status": "none",
      "stock": "Out of stock",
      "review": "(250 review)",
      "category": "Man",
      "colors": [
          "black",
          "pink",
          "red"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Denizen",
          "Spykar"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 23,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Woman T-shirt",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "100.00",
      "price": 120.00,
      "status": "none",
      "stock": "In stock",
      "review": "(250 review)",
      "category": "Woman",
      "colors": [
          "black",
          "pink",
          "red"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Diesel"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  },
  {
      "id": 24,
      "img": "assets/images/ecommerce/01.jpg",
      "name": "Man's Jacket",
      "note": "Simply dummy text of the printing",
      "discription": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      "discountPrice": "410.00",
      "price": 50.00,
      "status": "none",
      "stock": "In stock",
      "review": "(220 review)",
      "category": "Woman",
      "colors": [
          "black",
          "pink",
          "red",
          "gray"
      ],
      "size": [
          "M",
          "L",
          "XL"
      ],
      "tags": [
          "Nike",
          "Caprese",
          "Lee"
      ],
      "variants": [
          {
              "color": "White",
              "images": "assets/images/ecommerce/01.jpg"
          },
          {
              "color": "gray",
              "images": "assets/images/ecommerce/02.jpg"
          },
          {
              "color": "black",
              "images": "assets/images/ecommerce/03.jpg"
          },
          {
              "color": "pink",
              "images": "assets/images/ecommerce/04.jpg"
          }
      ]
  }
]
