import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {BlogSingleComponent} from "./features/blog/blog-single/blog-single.component";

const routes: Routes = [
  {
    path:'',
    loadChildren:()=>import('./features/home/home.module').then(m=> m.HomeModule)
  },
  {
    path:'auth',
    loadChildren:()=>import('./features/auth/auth.module').then(m=> m.AuthModule)
  },
  {
    path:'Request',
    loadChildren:()=>import('./features/requests/requests.module').then(m=> m.RequestsModule)
  },
  {
    path:'User',
    loadChildren:()=>import('./features/user-profile/user-profile.module').then(m=> m.UserProfileModule)
  },
  {
    path:'About-Eyas',
    loadChildren:()=>import('./features/about/about.module').then(m=> m.AboutModule)
  },
  {
    path:'blog',
    loadChildren:()=>import('./features/blog/blog.module').then(m=> m.BlogModule)
  },
  {
    path:'blog/:id/:title',
    component: BlogSingleComponent
  },
 
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [
  RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
