import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, ReplaySubject, map, of } from "rxjs";
import { IUser, UserResponse } from "./models/user";
import { IResponse } from "src/app/shared/model/response";
import { Country } from "./models/country";
import { AppDataService } from "src/app/shared/services/app-data.service";
import { IInstitutionActivity, IInstitutionType } from "./models/institution";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  apiUrl = environment.growthApiUrl;
  lang = localStorage.getItem("app-lang");
  token: any = localStorage.getItem("token");
  headers: any;

  public currentUserSource = new ReplaySubject<IUser | null>(1);
  currentUser$ = this.currentUserSource.asObservable();

  udId: string;
  deviceType: string;
  deviceModel: string;
  deviceVersion: string;
  appVersion: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private appDataService: AppDataService,
    private route: ActivatedRoute
  ) {
    this.headers = new HttpHeaders();
    this.udId = this.appDataService.generateRandomUdId();
    this.deviceType = this.appDataService.detectDeviceType();
    this.deviceModel = this.appDataService.getBrowserName();
    this.deviceVersion = this.appDataService.getBrowserVersion();
    this.appVersion = this.appDataService.generateAppVersion(
      environment.version.realaseDate,
      environment.version.number
    );
  }

  get isLoginPage(): boolean {
    return location.href.includes("auth/login");
  }

  login(credentials: any) {
    return this.http.post<UserResponse>(
      this.apiUrl + "/loginWithEmail",
      credentials
    );
  }

  confirmPassword(credentials: any) {
    this.lang = localStorage.getItem("app-lang") || "ar";
    this.headers = this.headers.set("Accept-Language", ` ${this.lang}`);
    return this.http.post<UserResponse>(
      this.apiUrl + "/passwordConfirmation",
      {
        ...credentials,
        app_version: this.appVersion,
        device_type: this.deviceType,
        device_version: this.deviceVersion,
        device_model: this.deviceModel,
        udId: this.udId,
      },
      { headers: this.headers }
    );
  }

  signUpWithEmail(credentials: any) {
    return this.http.post<UserResponse>(
      this.apiUrl + "/signUpWithEmail",
      credentials
    );
  }

  sendLoginCode(credentials: any) {
    this.lang = localStorage.getItem("app-lang") || "ar";
    this.headers = this.headers.set("Accept-Language", ` ${this.lang}`);

    return this.http.post<UserResponse>(
      this.apiUrl + "/sendLoginCode",
      credentials,
      { headers: this.headers }
    );
  }

  addPassword(credentials: any) {
    this.token = localStorage.getItem("temp_token");
    this.lang = localStorage.getItem("app-lang") || "ar";
    this.headers = this.headers.set("Authorization", `Bearer ${this.token}`);
    this.headers = this.headers.set("Accept-Language", ` ${this.lang}`);
    return this.http.post<UserResponse>(this.apiUrl + "/addPass", credentials, {
      headers: this.headers,
    });
  }

  confirmCode(credentials: any) {
    this.token = localStorage.getItem("token");
    this.lang = localStorage.getItem("app-lang") || "ar";
    this.headers = this.headers.set("Authorization", `Bearer ${this.token}`);
    this.headers = this.headers.set("Accept-Language", ` ${this.lang}`);
    return this.http.post<UserResponse>(
      this.apiUrl + "/emailConfirmCode",
      credentials,
      {
        headers: this.headers,
      }
    );
  }

  confirmVerifyCode(credentials: any) {
    this.lang = localStorage.getItem("app-lang") || "ar";
    this.headers = this.headers.set("Accept-Language", ` ${this.lang}`);
    return this.http.post<UserResponse>(
      this.apiUrl + "/ConfirmVerifyCode",
      {
        ...credentials,
        udId: this.udId,
      },
      { headers: this.headers }
    );
  }

  resendCode(data: any) {
    return this.http.post<UserResponse>(this.apiUrl + "/resendCode", data);
  }

  completeProfile(data: any) {
    this.token = localStorage.getItem("token");
    this.lang = localStorage.getItem("app-lang") || "ar";
    this.headers = this.headers.set("Authorization", `Bearer ${this.token}`);
    this.headers = this.headers.set("Accept-Language", ` ${this.lang}`);
    return this.http.post<UserResponse>(
      this.apiUrl + "/completeProfile",
      data,
      {
        headers: this.headers,
      }
    );
  }

  loadCurrentUser(token: string | null) {
    if (token == null) {
      this.currentUserSource.next(null);
      return of(null);
    }
    let headers = new HttpHeaders();
    headers = headers.set("Authorization", `Bearer ${token}`);
    headers = headers.set("Accept-language", `ar`);
    return this.http
      .put<any>(this.apiUrl + "/refresh", {}, { headers: headers })
      .subscribe(
        (res) => {
          if (res) {
            localStorage.setItem("token", res.token);
            this.currentUserSource.next(res.data);
            return res.data;
          } else {
            this.currentUserSource.next(null);
            return null;
          }
        },
        (err) => {
          console.log("HTTP Error", err);
          Swal.fire({
            title: "انتهت جلستك",
            text: "الرجاد الدخول على الحساب من جديد",
            icon: "error",
            confirmButtonText: "نعم",
          });
          localStorage.clear();
          this.currentUserSource.next(null);
          this.router.navigateByUrl("/auth/login");
        }
      );
  }

  logout() {
    localStorage.removeItem("token");
    this.currentUserSource.next(null);
    this.router.navigateByUrl("/auth/login");
  }

  signup(credentials: any) {
    return this.http
      .post<any>(this.apiUrl + "/userSignUp", credentials)
      .subscribe(
        (res) => {
          this.login({
            email: credentials.email,
            password: credentials.password,
            app_version: this.appVersion,
            device_type: this.deviceType,
            device_version: this.deviceVersion,
            device_model: this.deviceModel,
            udId: this.udId,
          });
          console.log(this.currentUserEmail());

          this.router.navigateByUrl("/auth/verify-email");
        },
        (error) => {
          console.error("Register failed:", error);
        }
      );
  }

  verifyEmail(code: any) {
    let email = this.currentUserEmail();
    return this.http
      .post<UserResponse>(this.apiUrl + "/emailConfirmCode", {
        email: email,
        verifyCode: code,
      })
      .subscribe(
        (res) => {
          console.log(res);
          this.router.navigateByUrl("/auth/complete-signup");
        },
        (error) => {
          console.log(error);
        }
      );
  }

  completeSignup(credentials: any) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    return this.http
      .post<any>(this.apiUrl + "/completeProfile", credentials, {
        headers: headers,
      })
      .subscribe(
        (res) => {
          this.router.navigateByUrl("/auth/complete-institution-data");
        },
        (error) => {
          console.error("Register failed:", error);
        }
      );
  }

  completeInstitutionData(credentials: any) {
    let id = this.currentUserId();
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers = headers.set(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    return this.http
      .post<any>(this.apiUrl + "/completeInstitutionData/" + id, credentials, {
        headers: headers,
      })
      .subscribe(
        (res) => {
          let userType;
          this.currentUserType().subscribe((res) => {
            userType = res;
          });
          if (userType == "CONSULTANT") {
            this.router.navigateByUrl("/home/lawyer");
          } else if (userType == "USER") {
            this.router.navigateByUrl("/home/client");
          }
        },
        (error) => {
          console.error("Register failed:", error);
        }
      );
  }

  getCountries(): Observable<any> {
    return this.http.get<IResponse<Country>>(
      this.apiUrl + "/countries/withoutPagenation/get"
    );
  }

  currentUserEmail(): Observable<string | undefined> {
    return this.currentUser$.pipe(map((user) => user?.email));
  }

  currentUserId() {
    let id: number = 0;
    this.currentUser$.subscribe((user) => {
      if (user) {
        id = user?.id;
      }
    });
    return id;
  }

  currentUserType() {
    return this.currentUser$.pipe(map((user) => user?.type));
  }

  getInstitutionTypes(): Observable<any> {
    return this.http.get<IResponse<IInstitutionType>>(
      this.apiUrl + "/institutionTypes/withoutPagenation/get"
    );
  }

  getInstitutionActivities(): Observable<any> {
    return this.http.get<IResponse<IInstitutionActivity>>(
      this.apiUrl + "/institutionActivities/withoutPagenation/get"
    );
  }
}
