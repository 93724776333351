import { Component } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {BlogService} from "../blog.service";

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.scss']
})
export class BlogSingleComponent {
  slug: string | null = null;
  post: any
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private blogService: BlogService,
  ) {
  }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get('title');
    console.log(this.slug);
    console.log(this.route.snapshot.paramMap);
    this.blogService.getPostById(this.slug).subscribe((res: any) => {
      this.post = res.data;
    });
  }

  navigateTo(link: string, target?: string) {
    if (link.includes("http")) window.open(link, target);
    else this.router.navigate([link]);
  }

  removeHtmlTags(text: string): string {
    const textFormatted =  text.replace(/<[^>]*>/g, '');
    return textFormatted.length > 250 ? textFormatted.substring(0, 250) : textFormatted;
  }
}
