
export interface supportDB {
    id: number,
    img: string;
    name:string;
    position: string;
    salary: string;
    office:string;
    skill:string;
    progress:string;
    extn:number;
    email:string;
    exp: string;
    ActionClass: string;
    iconClass: string;
    iconColor: string;
}

export const SUPPORTDB: supportDB[] = [
        {
            id: 1,
            img: "assets/images/user/1.jpg",
            name:"Elana",
            position: "System Architect",
            salary: "$320,800",
            office: "Edinburgh",
            skill: "progress-bar custom-progress-width bg-danger w-70",
            progress:"70%",
            extn: 5421,
            email: "t.nixon@datatables.net",
            exp: "2 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 2,
            img: "assets/images/user/2.jpg",
            name:"Tiger",
            position: "System Architect",
            salary: "$320,800",
            office: "Edinburgh",
            skill: "progress-bar custom-progress-width bg-secondary w-20",
            progress:"20%",
            extn: 4121,
            email: "t.nixon@datatables.net",
            exp: "1 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 3,
            img: "assets/images/user/3.jpg",
            name:"Genelia Winters",
            position: "Accountant",
            salary: "$170,750",
            office: "Tokyo",
            skill: "progress-bar custom-progress-width bg-success w-50",
            progress:"50%",
            extn: 8422,
            email: "g.winters@datatables.net",
            exp: "3 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 4,
            img: "assets/images/user/4.jpg",
            name:"Robbert Winters",
            position: "Accountant",
            salary: "$170,750",
            office: "Tokyo",
            skill: "progress-bar custom-progress-width bg-primary w-45",
            progress:"45%",
            extn: 6522,
            email: "g.winters@datatables.net",
            exp: "4 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 5,
            img: "assets/images/user/5.jpg",
            name:"Garrett Winters",
            position: "Accountant",
            salary: "$170,750",
            office: "Tokyo",
            skill: "progress-bar custom-progress-width bg-danger w-57",
            progress:"57%",
            extn: 1422,
            email: "g.winters@datatables.net",
            exp: "8 Month",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 6,
            img: "assets/images/user/6.jpg",
            name:"Ashton Cox",
            position: "Junior Technical Author",
            salary: "$86,000",
            office: "San Francisco",
            skill: "progress-bar custom-progress-width bg-success w-100",
            progress:"100%",
            extn: 1952,
            email: "a.cox@datatables.net",
            exp: "2 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 7,
            img: "assets/images/user/7.jpg",
            name:"Cedric Kelly",
            position: "Senior Javascript Developer",
            salary: "$433,060",
            office: "San Francisco",
            skill: "progress-bar custom-progress-width bg-secondary w-35",
            progress:"35%",
            extn: 6224,
            email: "c.kelly@datatables.net",
            exp: "2 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 8,
            img: "assets/images/user/8.jpg",
            name:"Helly Shah",
            position: "Senior Javascript Developer",
            salary: "$433,060",
            office: "San Francisco",
            skill: "progress-bar custom-progress-width bg-secondary w-76",
            progress:"76%",
            extn: 5678,
            email: "c.kelly@datatables.net",
            exp: "4 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 9,
            img: "assets/images/user/9.jpg",
            name:"Airi Satou",
            position: "Accountant",
            salary: "$162,700",
            office: "Tokyo",
            skill: "progress-bar custom-progress-width bg-success w-20",
            progress:"20%",
            extn: 4789,
            email: "a.satou@datatables.net",
            exp: "3 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 10,
            img:'assets/images/user/10.jpg',
            name:"Hendri Feyol",
            position: "Integration Specialist",
            salary: "$372,000",
            office: "New York",
            skill: "progress-bar custom-progress-width bg-info w-30",
            progress:"30%",
            extn: 3804,
            email: "b.williamson@datatables.net",
            exp: "1 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 11,
            img: "assets/images/user/1.jpg",
            name:"Herrod Chandler",
            position: "Sales Assistant",
            salary: "$137,500",
            office: "San Francisco",
            skill: "progress-bar custom-progress-width bg-warning w-60",
            progress:"60%",
            extn: 9608,
            email: "h.chandler@datatables.net",
            exp: "1 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 12,
            img: 'assets/images/user/5.jpg',
            name:"Rhona Davidson",
            position: "Integration Specialist",
            salary: "$327,900",
            office: "Tokyo",
            skill: "progress-bar custom-progress-width bg-secondary w-50",
            progress:"50%",
            extn: 6200,
            email: "r.davidson@datatables.net",
            exp: "4 Month",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 13,
            img: "assets/images/user/1.jpg",
            name:"Colleen Hurst",
            position: "Javascript Developer",
            salary: "$205,500",
            office: "San Francisco",
            skill: "progress-bar custom-progress-width bg-success w-25",
            progress:"25%",
            extn: 2360,
            email: "c.hurst@datatables.net",
            exp: "3 Month",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 14,
            img: "assets/images/user/2.jpg",
            name:"Sonya Frost",
            position: "Software Engineer",
            salary: "$103,600",
            office: "Edinburgh",
            skill: "progress-bar custom-progress-width bg-primary w-40",
            progress:"40%",
            extn: 1667,
            email: "s.frost@datatables.net",
            exp: "1 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 15,
            img: "assets/images/user/3.jpg",
            name:"Jena Gaines",
            position: "Office Manager",
            salary: "$90,560",
            office: "London",
            skill: "progress-bar custom-progress-width bg-warning w-57",
            progress:"57%",
            extn: 3814,
            email: "j.gaines@datatables.net",
            exp: "3 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 16,
            img: "assets/images/user/4.jpg",
            name:"Quinn Flynn",
            position: "Support Lead",
            salary: "$342,000",
            office: "Edinburgh",
            skill: "progress-bar custom-progress-width bg-danger w-50",
            progress:"50%",
            extn: 9497,
            email: "q.flynn@datatables.net",
            exp: "6 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 17,
            img: "assets/images/user/5.jpg",
            name:"Charde Marshall",
            position: "Regional Director",
            salary: "$470,600",
            office: "San Francisco",
            skill: "progress-bar custom-progress-width bg-info w-27",
            progress:"27%",
            extn: 6741,
            email: "c.marshall@datatables.net",
            exp: "6 Month",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 18,
            img: "assets/images/user/6.jpg",
            name:"Haley Kennedy",
            position: "Senior Marketing Designer",
            salary: "$313,500",
            office: "London",
            skill: "progress-bar custom-progress-width bg-success w-35",
            progress:"35%",
            extn: 3597,
            email: "h.kennedy@datatables.net",
            exp: "3 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 19,
            img: "assets/images/user/7.jpg",
            name:"Tatyana Fitzpatrick",
            position: "Regional Director",
            salary: "$385,750",
            office: "London",
            skill: "progress-bar custom-progress-width bg-warning w-80",
            progress:"80%",
            extn: 1965,
            email: "t.fitzpatrick@datatables.net",
            exp: "8 Month",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 20,
            img: "assets/images/user/8.jpg",
            name:"Michael Silva",
            position: "Marketing Designer",
            salary: "$198,500",
            office: "London",
            skill: "progress-bar custom-progress-width bg-primary w-74",
            progress:"74%",
            extn: 1581,
            email: "m.silva@datatables.net",
            exp: "4 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 21,
            img: "assets/images/user/9.jpg",
            name:"Michael Silva",
            position: "Paul Byrd",
            salary: "Chief Financial Officer (CFO)",
            office: "$725,000",
            skill: "progress-bar custom-progress-width bg-ress-bar w-30 bg-success",
            progress:"30%",
            extn: 3059,
            email: "p.byrd@datatables.net",
            exp: "1 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 22,
            img: "assets/images/user/10.jpg",
            name:"Gloria Little",
            position: "Systems Administrator",
            salary: "$237,500",
            office: "New York",
            skill: "progress-bar custom-progress-width bg-success w-50",
            progress:"50%",
            extn: 1721,
            email: "g.little@datatables.net",
            exp: "3 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 23,
            img: "assets/images/user/2.jpg",
            name:"Bradley Greer",
            position: "Software Engineer",
            salary: "$132,000",
            office: "London",
            skill: "progress-bar custom-progress-width bg-primary w-30",
            progress:"30%",
            extn: 2558,
            email: "b.greer@datatables.net",
            exp: "4 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 24,
            img: "assets/images/user/5.jpg",
            name:"Michael Silva",
            position: "Dai Rios",
            salary: "Personnel Lead",
            office: "$217,500",
            skill: "progress-bar custom-progress-width  bg-warning w-40",
            progress:"40%",
            extn: 2290,
            email: "d.rios@datatables.net",
            exp: "7 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 25,
            img: "assets/images/user/1.jpg",
            name:"Jenette Caldwell",
            position: "Development Lead",
            salary: "$345,000",
            office: "New York",
            skill: "progress-bar custom-progress-width bg-primary w-30",
            progress:"80%",
            extn: 1937,
            email: "j.caldwell@datatables.net",
            exp: "2 Month",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 26,
            img: "assets/images/user/2.jpg",
            name:"Yuri Berry",
            position: "Chief Marketing Officer (CMO)",
            salary: "$675,000",
            office: "New York",
            skill: "progress-bar custom-progress-width bg-danger w-20",
            progress:"30%",
            extn: 6154,
            email: "y.berry@datatables.net",
            exp: "2 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        },
        {
            id: 27,
            img: "assets/images/user/3.jpg",
            name:"C",
            position: "Pre-Sales Support",
            salary: "$106,450",
            office: "New York",
            skill: "progress-bar custom-progress-width bg-success w-10",
            progress:"70%",
            extn: 8330,
            email: "c.vance@datatables.net",
            exp: "2 Year",
            ActionClass: 'badge-light-primary',
            iconClass: 'icofont-arrow-up',
            iconColor: 'success',
        }
    ]

export const ticketListStatus = [
    {
        statusTitle: "Order",
        order: 2563,
        profit: 8989,
        loss: 2560,
        laval: "70%",
        lavalColor: "primary" 
    },
    {
        statusTitle: "Pending",
        order: 9858,
        profit: 8989,
        loss: 2560,
        laval: "50%",
        lavalColor: "secondary"
    },
    {
        statusTitle: "Running",
        order: 6552,
        profit: 8989,
        loss: 2560,
        laval: "70%",
        lavalColor: "warning"
    },
    {
        statusTitle: "Smooth",
        order: 1512,
        profit: 8989,
        loss: 2560,
        laval: "70%",
        lavalColor: "info"

    },
    {
        statusTitle: "Done",
        order: 2563,
        profit: 8989,
        loss: 2560,
        laval: "80%",
        lavalColor: "success"

    },
    {
        statusTitle: "cancel",
        order: 2563,
        profit: 8989,
        loss: 2560,
        laval: "50%",
        lavalColor: "danger"

    },
]
