import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  private isLogginedSource = new BehaviorSubject<boolean>(false);
  isLoggined$ = this.isLogginedSource.asObservable();
  generateAppVersion(releaseDate: Date, versionNumber: number): string {
    const year = releaseDate.getFullYear();
    const month = (releaseDate.getMonth() + 1).toString().padStart(2, '0');
    const day = releaseDate.getDate().toString().padStart(2, '0');
  
    const appVersion = `${year}.${month}${day}.${versionNumber}`;

    return appVersion;
  }

  generateRandomUdId() {
    let udId = '';
    const characters = '0123456789abcdef';
    for (let i = 0; i < 32; i++) {
      udId += characters[Math.floor(Math.random() * characters.length)];
    }
    return udId;
  }
  
  detectDeviceType() {
      const userAgent = navigator.userAgent;
      if (userAgent.match(/Android/i)) {
        return 'ANDROID';
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        return 'IOS';
      } else {
        return 'WEB';
    }
  }

  getBrowserName() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Chrome') !== -1) {
      return 'CHROME';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      return 'FIREFOX';
    } else if (userAgent.indexOf('Safari') !== -1) {
      return 'SAFARI';
    } else if (userAgent.indexOf('Edge') !== -1) {
      return 'EDGE';
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
      return 'INTERNET EXPLORER';
    } else {
      return 'Unknown';
    }
  }

  getBrowserVersion() {
    const userAgent = navigator.userAgent;
    const versionMatch = userAgent.match(/(Chrome|Firefox|Safari|Edge|MSIE|Trident)\/[\d.]+/);
    
    if (versionMatch) {
      return versionMatch[0].split('/')[1];
    } else {
      return 'Unknown';
    }
  }
  



setIsLoggined(value: boolean): void {
  this.isLogginedSource.next(value);
}


}
