import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    constructor(private http: HttpClient) {
    }

    getMainCategories() {
        return this.http.get(
            `${environment.growthApiUrl}/blogCategories/withoutPagenation/get`,
        );
    }

    getPostsByCategory(categoryId: null | string = null, page: number = 1) {
        const filter = categoryId ? `?category=${categoryId}&limit=20` : '?limit=20';
        return this.http.get(
            `${environment.growthApiUrl}/blogs/getPagenation${filter}&page=${page}`,
        );
    }

    getPostById(slug: string | null) {
        return this.http.get(
            `${environment.growthApiUrl}/blogs/slug/${slug}`,
        );
    }
}
